<template>
    <Header />
    <HomeCard />
    <SobreCard />
    <DemoCard />
    <ContatoCard />
    <Footer />
</template>

<script>
import Header from '../components/Header.vue';
import HomeCard from '../components/HomeCard.vue';
import SobreCard from '../components/SobreCard.vue';
import DemoCard from '../components/DemoCard.vue';
import ContatoCard from '../components/ContatoCard.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'HomeView',
  components: {
    Header,
    HomeCard,
    SobreCard,
    DemoCard,
    ContatoCard,
    Footer,
  },
  methods: {
    
  },
  mounted() {

  },
}
</script>

