<template>
  <!-- <h1>Valor do parâmetro: {{ email }}</h1> -->
  <HeaderLogged />
  <HomeCardLogged />
  <Contagem />
  <Footer />
</template>
  
  <script>
  import HeaderLogged from '../components/HeaderLogged.vue';
  import HomeCardLogged from '../components/HomeCardLogged.vue';
  import Footer from '../components/Footer.vue'
  import Contagem from '@/components/Contagem.vue';

  export default {
    name: 'HomeLogged',
    components: {
      HeaderLogged,
      HomeCardLogged,
      Contagem,
      Footer
    },
    // data() {
    //   return {
    //     email: this.$route.params.email
    //   }
    // },
    methods: {
  
    },
    mounted() {
  
    },
  //   computed: {
  //   parametro() {
  //     return this.$route.params.email
  //   }
  // }
  }
  // console.log(this.$route.params.email)
  </script>
  
  