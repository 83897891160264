<template>
  <section id="contact" class="contact">
    <div class="container">

      <div class="section-title">
        <h2 style="text-align: left;">Contagem de Peixes</h2>
        <p style="text-align: left;">Faça a contagem dos peixes</p>
      </div>

      <div class="row">
        <div class="col-lg-1 mt-5 mt-lg-0"></div>
        <div class="col-lg-4 mt-5 mt-lg-0">
          <form @submit.prevent="uploadImage" enctype="multipart/form-data" method="post" role="form"
            class="php-email-form">
            <div class="form-group mt-3">
              <input type="text" class="form-control" name="lote" id="lote" placeholder="Lote" v-model="lote" required>
            </div>

            <div class="form-group mt-3">
              <!-- <input type="text" class="form-control" name="especie" id="especie" placeholder="Espécie" v-model="especie" required> -->


              <!-- <label for="selectFish">Selecione uma espécie de peixe:</label> -->
              <div>
                <select class="form-control" v-model="especieSelecionada">
                  <option value="">Selecione uma espécie</option>
                  <option v-for="especie in especiesOrdenadas" :value="especie.especie">{{ especie.especie }}</option>
                </select>
              </div>
            </div>

            <div class="form-group mt-3">
              <select class="form-control" v-model="sexo">
                <option>Sexo</option>
                <option value="macho">macho</option>
                <option value="fêmea">fêmea</option>
                <option value="hermafrodita">hermafrodita</option>
                <option value="assexuado">assexuado</option>
              </select>
            </div>
            <file-pond name="test" type="file" ref="pond" label-idle="Envie sua foto aqui!" v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png" v-bind:files="myFiles" v-on:init="handleFilePondInit"
              @change="onFileChange" />
            <div class="text-center"><button type="submit">Enviar</button></div>



            <!-- <div class="text-center"><button  type="submit">Enviar</button></div> -->
          </form>
        </div>
        <div class="col-lg-1 mt-5 mt-lg-0"></div>
        <div class="col-lg-6" data-aos-delay="100">
          <div class="info">
            <div class="email">
              <h2>Resultado:</h2>
              <h1>{{ resultado && resultado.contagem ? resultado.contagem : '0' }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
    
<script>
import vueFilePond from "vue-filepond";
import especies from '/src/assets/data/peixes.json';
import axios from 'axios';
import "../../node_modules/filepond/dist/filepond.min.css"
import "../assets/css/filepond-plugin-image-preview.css"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, doc, getDoc, addDoc} from "firebase/firestore";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  name: 'Contagem',
  data() {
    return {
      lote: '',
      especie: '',
      sexo: 'Sexo',
      errorMsg: null,
      resultado: null,
      especies: especies,
      especieSelecionada: '',

      // resultado.contagem = 0
    };
  },
  components: {
    FilePond,
  },
  methods: {
    onFileChange(e) {
      this.image = e.target.files[0];
    },
    fazerContagem() {
      console.log(this.lote);
      console.log(this.especie);
      console.log(this.sexo);
    },
    uploadImage() {
      const formData = new FormData();
      formData.append('image', this.image);

      fetch('http://127.0.0.1:5000/count', {
        method: 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          this.resultado = data;

          const auth = getAuth();
          const firestore = getFirestore();
          auth.onAuthStateChanged((user) => {
            if (user) {
              const userId = user.uid;
              const docRef = doc(firestore, 'usuarios', userId);
              getDoc(docRef)
                .then((doc) => {
                  if (doc.exists()) {
                    const contagemRef = collection(docRef, 'contagem');
                    const newContagem = {
                      lote: this.lote,
                      especie: this.especieSelecionada,
                      sexo: this.sexo,
                      resultado: this.resultado.contagem,
                    };

                    addDoc(contagemRef, newContagem)
                      .then(() => {
                        console.log('Informações de contagem adicionadas com sucesso!');
                        this.lote = '';
                        this.especieSelecionada = '';
                        this.sexo = '';
                      })
                      .catch((error) => {
                        console.error('Erro ao adicionar informações de contagem:', error);
                      });
                  } else {
                    console.log('Documento de usuário não encontrado!');
                  }
                })
                .catch((error) => {
                  console.error('Erro ao obter documento de usuário:', error);
                });
            }
          });
        })
        .catch(error => console.error(error));
    },

    submitForm() {
      if (!this.image) {
        this.errorMsg = 'Por favor, selecione uma imagem!';
        return;
      }

      if (this.image.size > 2000000) {
        this.errorMsg = 'O tamanho da imagem deve ser menor que 2 MB!';
        return;
      }
      const formData = new FormData();
      formData.append('image', this.image);
      fetch('http://127.0.0.1:5000/count', {
        method: 'POST',
        body: formData,
      })
        .then(response => response.text())
        .then(data => console.log(data))
        .catch(error => console.error(error));
    },
  },computed: {
    especiesOrdenadas() {
      return especies.sort((a, b) => a.especie.localeCompare(b.especie));
    },
  },
};
</script>
    