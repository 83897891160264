<template>
    <HeaderLogged />
    <SobreCard />
    <Footer />
</template>

<script>
import HeaderLogged from '../components/HeaderLogged.vue';
import SobreCard from '../components/SobreCard.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'Sobre',
  components: {
    HeaderLogged,
    SobreCard,
    Footer,
  },
  methods: {

  },
  mounted() {

  },
}
</script>

