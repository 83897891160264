import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HomeLogged from '../views/HomeLogged.vue'
import Login from '../views/Login.vue'
import Cadastro from '../views/Cadastro.vue'
import Sobre from '../views/Sobre.vue'
import Contato from '../views/Contato.vue'
import Historico from '../views/Historico.vue'
// import Teste from '../views/Teste.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/cadastro',
    name: 'cadastro',
    component: Cadastro
  },
  {
    // path: '/home/:email',
    path: '/home/',
    name: 'homelogged',
    component: HomeLogged
  },
  {
    path: '/contato',
    name: 'contato',
    component: Contato
  },
  {
    path: '/sobre',
    name: 'sobre',
    component: Sobre
  },
  {
    path: '/historico',
    name: 'historico',
    component: Historico
  }
  // {
  //   path: '/teste',
  //   name: 'teste',
  //   component: Teste
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
