import Vue, { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import firebase from "firebase/app";


import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBjAddjZHhiYTExMOGJ4rZlUv_pKTeV3RM",
  authDomain: "onpeixes-6df0e.firebaseapp.com",
  projectId: "onpeixes-6df0e",
  storageBucket: "onpeixes-6df0e.appspot.com",
  messagingSenderId: "262823945552",
  appId: "1:262823945552:web:38c2ee45b7188ec57b7855",
  measurementId: "G-H11VX24233"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app); 
const firestore = getFirestore(app);

  
createApp(App)
    .use(router)
    .provide("auth", auth) // disponibilizando o módulo de autenticação para a sua aplicação
    .provide("firestore", firestore) // disponibilizando o módulo do Firestore para a sua aplicação
    .mount('#app')

export { firestore };

