<template>
  <HeaderLogged />
  <div class="container" >
    <div class="row " style="align-items: flex; justify-content: center; padding-top: 4rem;">
      <div class="col-md-offset-1 col-md-10">
        <div class="panel">
          <div class="panel-heading">
            <div class="row" style="">
              <div class="col col-sm-3 col-xs-12">
                <h4 class="title">Histórico de Contagens</h4>
              </div>
              <div class="table-responsive">
                <table class="table" >
                  <thead>
                    <tr>
                      <th>Espécie</th>
                      <th>Lote</th>
                      <th>Sexo</th>
                      <th>Resultado</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="contagem in contagens" :key="contagem.id">
                      <td>{{ contagem.especie }}</td>
                      <td>{{ contagem.lote }}</td>
                      <td>{{ contagem.sexo }}</td>
                      <td>{{ contagem.resultado }}</td>
                      <td>
                        R$ {{ calcularValor(contagem.resultado) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="padding-top: 13rem;"></div>
  <Footer />
</template>

<script>
import HeaderLogged from '../components/HeaderLogged.vue';
import Footer from '../components/Footer.vue';
import { firestore } from '../main'
import { getAuth } from "firebase/auth";
import { getDocs, collection, where, query, getDoc } from "firebase/firestore";

export default {
  name: "Historico",
  components: {
    HeaderLogged,
    Footer
  },
  data() {
    return {
      contagens: [],
      valor: 0,
    };
  },
  async created() {
    const auth = getAuth();
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.carregarContagens(user.uid);
        this.calcularValor()
        // console.log('1')
      } else {
        // Usuário não está autenticado, limpa a lista de contagens
        this.contagens = [];
        // console.log('2')
      }
    });
    // console.log(this.contagens)
  },
  methods: {
    async carregarContagens(userId) {
      const contagensRef = collection(firestore, 'usuarios', userId, 'contagem');
      const querySnapshot = await getDocs(contagensRef);
      this.contagens = querySnapshot.docs.map((doc) => doc.data());
      // console.log(this.contagens);
    },
    calcularValor(resultado) {
      const qtsval = 0.10;
      const valor = parseFloat(resultado) * qtsval;
      return isNaN(valor) ? 0 : valor.toFixed(2);
    }
  }
};
</script>
<style src="@/assets/css/table.css"></style>


