<template>
    <header id="header" class="fixed-top d-flex align-items-center ">
        <div class="container d-flex align-items-center justify-content-between">
          <div class="logo">
            <h1><a href="#hero"><span>OnPeixes</span></a></h1>
          </div>
    
          <nav id="navbar" class="navbar">
            <ul>
              <li style="padding-right: 2rem;"><a class="nav-link scrollto active" href="" @click="goToHome">Home</a></li>
              <li style="padding-right: 2rem;"><a class="nav-link scrollto" href="" @click="goToSobre">Sobre</a></li>
              <li style="padding-right: 2rem;"><a class="nav-link scrollto" href="" @click="goToContato">Contato</a></li>
              <!-- <li><a class="nav-link scrollto" href="" @click="goToLogin">Sair</a></li> -->
              <li class="dropdown"><a href="#"><span>{{nomeEmpresa}}</span> <i class="bi bi-chevron-down"></i></a>
                <ul>
                  <li><a href="#" @click="goToHistorico">Meus gastos</a></li>
                  <li><a href="#">Editar Perfil</a></li>
                  <li><a href="#">Formas de pagamento</a></li>
                  <li><a href="#">Ajuda</a></li>
                  <li class="dropdown-divider"></li>
                  <li><a href="#"  @click="goToLogin">Sair</a></li>
                </ul>
              </li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header>
    </template>
    
    // HeaderLogged.vue

    // HeaderLogged.vue

    <script>
    import { getAuth } from "firebase/auth";
    import { getFirestore, collection, doc, getDoc } from "firebase/firestore";
    
    export default {
      name: 'HeaderLogged',
      data() {
        return {
          nomeEmpresa: '',
        };
      },
      methods: {
        goToLogin() {
          this.$router.push('/login');
        },
        goToHome() {
          this.$router.push('/home');
        },
        goToSobre() {
          this.$router.push('/sobre');
        },
        goToContato() {
          this.$router.push('/contato');
        },
        goToHistorico() {
          this.$router.push('/historico');
        },
        login() {
          // Faça algo com os dados do login
        }
      },
      created() {
        const auth = getAuth();
        const firestore = getFirestore();
        auth.onAuthStateChanged((user) => {
          if (user) {
            const userId = user.uid;
            const docRef = doc(firestore, 'usuarios', userId);
            getDoc(docRef)
              .then((doc) => {
                if (doc.exists()) {
                  // console.log(doc.data())
                  const nomeDaEmpresaRecebidoDoFirestore = doc.data().nome_da_empresa;
                  this.nomeEmpresa = nomeDaEmpresaRecebidoDoFirestore;
                } else {
                  console.log('Documento não encontrado!');
                }
              })
              .catch((error) => {
                console.error('Erro ao obter documento:', error);
              });
          }
        });
      }
    };
    </script>
    

    
    
    <style>
    @import "@/assets/css/style.css";
    @import "@/assets/vendor/aos/aos.css";
    @import "@/assets/vendor/bootstrap-icons/bootstrap-icons.css";
    @import "@/assets/vendor/boxicons/css/boxicons.min.css";
    @import "@/assets/vendor/glightbox/css/glightbox.min.css";
    @import "@/assets/vendor/remixicon/remixicon.css";
    @import "@/assets/vendor/swiper/swiper-bundle.min.css";
    </style>