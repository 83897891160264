<template>
    <section id="contact" class="contact">
        <div class="container">

            <div class="section-title">
                <h2 style="text-align: left;">Contato</h2>
                <p style="text-align: left;">Entre em contato</p>
            </div>

            <div class="row">
                <div class="col-lg-4" data-aos-delay="100">
                    <div class="info">
                        <div class="address">
                            <i class="bi bi-geo-alt"></i>
                            <h4 style="text-align: left;">Local:</h4>
                            <p style="text-align: left;">Rodovia Sul Goiana, km 01, Zona Rural. | Rio Verde - GO | CEP:
                                75.901-970 - Brasil</p>
                        </div>

                        <div class="email">
                            <i class="bi bi-envelope"></i>
                            <h4 style="text-align: left;">Email:</h4>
                            <p style="text-align: left;">onpeixes@gmail.com</p>
                        </div>

                        <div class="phone">
                            <i class="bi bi-phone"></i>
                            <h4 style="text-align: left;">Telefone:</h4>
                            <p style="text-align: left;">+55 64 98118-6749</p>
                        </div>

                    </div>

                </div>

                <div class="col-lg-8 mt-5 mt-lg-0">

                    <form action="forms/contact.php" method="post" role="form" class="php-email-form">
                        <div class="form-group mt-3">
                            <input type="text" class="form-control" name="subject" id="subject" placeholder="Assunto"
                                required>
                        </div>
                        <div class="form-group mt-3">
                            <textarea class="form-control" name="message" rows="5" placeholder="Mensagem"
                                required></textarea>
                        </div>
                        <div class="my-3">
                            <div class="loading">Loading</div>
                            <div class="error-message"></div>
                            <div class="sent-message">Your message has been sent. Thank you!</div>
                        </div>
                        <div class="text-center"><button type="submit">Enviar</button></div>
                    </form>

                </div>

            </div>

        </div>
    </section>
</template>
  
<script>
export default {
    name: 'ContatoCard',
    methods: {
    },
    mounted() {
    },
}
</script>
  
  