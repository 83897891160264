<template>
<section id="hero2">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
            <div>
              <h1 style="text-align: left; padding-top: 5.5rem;">Faça agora um teste <span>gratuito</span> de nosso <span>sistema</span>.</h1>
              <h2 style="text-align: left; padding-top: 1rem;">A OnPeixes oferece um teste gratuito, contanto que o número de peixes seja menor que x</h2>
              <div class="text-lg-start" style="text-align: left;">
                <a href="" @click="goToLogin" class="btn-get-started scrollto">Fazer Demonstração</a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 order-1 order-lg-2 hero-img">
            <img src="../../public/peixes_favicon.png" class="img-fluid animated" alt="">
          </div>
        </div>
      </div>
    <svg class="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
    </svg> 
    </section>
</template>
  
<script>
export default {
    name: 'DemoCard',
    components: {
    },
    methods: {
      goToLogin() {
      this.$router.push('/login');
    },
    },
    mounted() {
    },
}
</script>
  