<template>
  <HeaderSign />
  <!-- Aleta de senha incorreta -->
  <div v-if="showError" class="alert alert-danger alert-dismissible fade show" role="alert">
    Senha incorreta ou usuário não cadastrado
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
  <section class="h-100 gradient-form" style="background-color: #eee;">
   <div class="container py-5 h-100">
     <div class="row d-flex justify-content-center align-items-center h-100">
       <div class="col-xl-10">
         <div class="card rounded-3 text-black">
           <div class="row g-0">
             <div class="col-lg-6 d-flex align-items-center gradient-custom-2">
               <div class="text-white px-3 py-4 p-md-5 mx-md-4">
                 <h4 class="mb-4">O que é a OnPeixes?</h4>
                 <p class="small mb-0" style="text-align: justify;">OnPeixes é uma empresa Startup que utiliza inteligência artificial para a contagem de peixes. Com soluções precisas e acessíveis, nossa equipe de estudantes universitários apaixonados por tecnologia busca contribuir para a sustentabilidade ambiental.</p>
               </div>
             </div>
             <div class="col-lg-6">
               <div class="card-body p-md-5 mx-md-4">
                 <form @submit.prevent="cadastro">
                  <p style="text-align: left;">Nome da empresa:</p>
 
                  <div class="form-outline mb-4">
                      <input type="text" id="nome_da_empresa" class="form-control" v-model="nome_da_empresa" required/>
                  </div>
                  
                  <p style="text-align: left;">CNPJ:</p>
 
                  <div class="form-outline mb-4">
                      <input type="text" id="cnpj_da_empresa" class="form-control" v-model="cnpj_da_empresa" required/>
                  </div>

                   <p style="text-align: left;">E-mail:</p>
 
                   <div class="form-outline mb-4">
                     <input type="email" id="email_da_empresa" class="form-control" v-model="email_da_empresa" required/>
                   </div>
 
                   <p style="text-align: left;">Senha:</p>
                   <!-- <p>Informe sua senha</p> -->
                   <div class="form-outline mb-4">
                     <input type="password" id="password_da_empresa" class="form-control" v-model="password_da_empresa" @input="checkPassword" required/>
                     <span v-if="passwordError" class="text-danger">{{ passwordError }}</span>
                   </div>

                  <div class="text-center pt-1 mb-5 pb-1">
                      <button :disabled="!allInputsFilled" class="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3" type="button" @click="cadastro()">Cadastrar</button>
                  </div>
                  <p>Já é cadastrado? faça o <a class="link-primary" href="/Login">login</a></p>
                  </form>   
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </section>

 <Footer />
   </template>
   
<!-- <script>
// import { auth } from "@/firebase.js";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

export default {
  name: 'Cadastro',
  data() {
    return {
      nome_da_empresa: '',
      cnpj_da_empresa: '',
      email_da_empresa: '',
      password_da_empresa: '',
      showError: false,
      passwordError: null,
      showPassword: false
    }
  },
  methods: {
      async cadastro() {
      const auth = getAuth();
      console.log(this.email_da_empresa, this.password_da_empresa)
      createUserWithEmailAndPassword(auth, this.email_da_empresa, this.password_da_empresa)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log(user)
          this.$router.push('/')
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
        });
      },
      checkPassword() {
        if (this.password_da_empresa.length < 8) {
          this.passwordError = 'A senha deve ter pelo menos 8 caracteres'
        } else {
          this.passwordError = null
        }
      },
  },
  computed: {
  allInputsFilled() {
    return this.nome_da_empresa && this.email_da_empresa && (this.password_da_empresa.length > 7) && this.cnpj_da_empresa
  }
}
}
</script> -->

<script>
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, collection, doc, setDoc} from "firebase/firestore";
import HeaderSign from '../components/HeaderSign.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'Cadastro',
  data() {
    return {
      nome_da_empresa: '',
      cnpj_da_empresa: '',
      email_da_empresa: '',
      password_da_empresa: '',
      showError: false,
      passwordError: null,
      showPassword: false
    }
  },
  methods: {
      async cadastro() {
      const auth = getAuth();
      console.log(this.email_da_empresa, this.password_da_empresa)
      createUserWithEmailAndPassword(auth, this.email_da_empresa, this.password_da_empresa)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log(user)

          // Salva o nome e cnpj da empresa junto com o uid do usuário no Firestore
          const db = getFirestore();
          const userRef = doc(db, "usuarios", user.uid);
          setDoc(userRef, {
            nome_da_empresa: this.nome_da_empresa,
            cnpj_da_empresa: this.cnpj_da_empresa,
            email_da_empresa: this.email_da_empresa,
          })
          .then(() => {
            console.log("Document successfully written!");
            this.$router.push('/login')
          })
          .catch((error) => {
              console.error("Error writing document: ", error);
          });

        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
        });
      },
      checkPassword() {
        if (this.password_da_empresa.length < 8) {
          this.passwordError = 'A senha deve ter pelo menos 8 caracteres'
        } else {
          this.passwordError = null
        }
      },
      GoToLogin() {
      this.$router.push('/login')
    },
  },
  computed: {
  allInputsFilled() {
    return this.nome_da_empresa && this.email_da_empresa && (this.password_da_empresa.length > 7) && this.cnpj_da_empresa
  },
},
components: {
      HeaderSign,
      Footer
    },
}
</script>




<style>
  @import "@/assets/css/login.css";
</style>