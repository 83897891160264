<template>
    <HeaderLogged />
    <ContatoCard style="padding-top: 11rem;" />
    <Footer />
</template>

<script>
import HeaderLogged from '../components/HeaderLogged.vue';
import ContatoCard from '../components/ContatoCard.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'Contato',
  components: {
    HeaderLogged,
    ContatoCard,
    Footer
  },
  methods: {

  },
  mounted() {

  },
}
</script>

