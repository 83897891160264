<template>

<footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-6 col-md-6">
            <div class="footer-info">
              <h3>OnPeixes</h3>
              <p class="pb-3"><em>Startup de IA que conta peixes com soluções acessíveis e precisas. Estudantes universitários apaixonados por tecnologia lideram o projeto.</em></p>
              <p>
                Rodovia Sul Goiana, km 01, Zona Rural.<br>Rio Verde - GO<br>CEP: 75.901-970<br>Brasil <br>
                <strong>Telefone:</strong> +55 64 98118-6749<br>
                <strong>Email:</strong> onpeixes@gmail.com<br>
              </p>
              <div class="social-links mt-3">
                <a href="https://www.facebook.com/onpeixes" class="facebook"><i class="bx bxl-facebook"></i></a>
                <a href="https://www.instagram.com/onpeixes/" class="instagram"><i class="bx bxl-instagram"></i></a>
                <a href="mailto:onpeixes@gmail.com" class="google"><i class="bx bxl-google"></i></a>
                <a href="https://wa.me/5564981186749" class="whatsapp"><i class="bx bxl-whatsapp"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 footer-links">
            <h3>Nossos serviços</h3>
            <ul style="padding-top: 3rem;">
              <li><i class="bx bx-chevron-right"></i> <a href="" @click="goToLogin">Contagem de Peixes</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="" @click="goToLogin">Teste de Contagem</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>OnPeixes</span></strong>. Todos os direitos reservados
      </div>
    </div>
  </footer>
</template>
  
<script>
export default {
    name: 'Footer',
    methods: {
      goToLogin() {
      this.$router.push('/login');
    },
    },
    mounted() {
    },
}
</script>
  