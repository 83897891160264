<template>
<header id="header" class="fixed-top d-flex align-items-center ">
    <div class="container d-flex align-items-center justify-content-between">
      <div class="logo">
        <h1><a href="#hero"><span>OnPeixes</span></a></h1>
      </div>

      <nav id="navbar" class="navbar">
        <ul>
          <li style="padding-right: 2rem;"><a class="nav-link scrollto active" href="#hero">Home</a></li>
          <li style="padding-right: 2rem;"><a class="nav-link scrollto" href="#about">Sobre</a></li>
          <li style="padding-right: 2rem;"><a class="nav-link scrollto" href="#hero2">Demonstração</a></li>
          <li style="padding-right: 2rem;"><a class="nav-link scrollto" href="#contact">Contato</a></li>
          <li><a class="nav-link scrollto" href="" @click="goToLogin">Entrar</a></li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>
    </div>
  </header>
</template>

<script>
import'../assets/vendor/purecounter/purecounter_vanilla.js';

import'../assets/vendor/aos/aos.js';
import'../assets/vendor/bootstrap/js/bootstrap.bundle.min.js';
import'../assets/vendor/glightbox/js/glightbox.min.js';
import'../assets/vendor/swiper/swiper-bundle.min.js';
import'../assets/vendor/php-email-form/validate.js';
// import'../assets/js/main.js';

export default {
  name: 'Header',
  methods: {
    goToLogin() {
      this.$router.push('/login')
    }
  }
}
</script>

<style>
@import "@/assets/css/style.css";
@import "@/assets/vendor/aos/aos.css";
@import "@/assets/vendor/bootstrap-icons/bootstrap-icons.css";
@import "@/assets/vendor/boxicons/css/boxicons.min.css";
@import "@/assets/vendor/glightbox/css/glightbox.min.css";
@import "@/assets/vendor/remixicon/remixicon.css";
@import "@/assets/vendor/swiper/swiper-bundle.min.css";
</style>