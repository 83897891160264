<template>
    <section id="hero">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
            <div>
              <h1 style="text-align: left; padding-top: 5.5rem;">Gerencie a quantidade de peixes de forma <span>rápida</span> e <span>prática</span></h1>
              <h2 style="text-align: left; padding-top: 1rem;">OnPeixes apresenta uma solução de inteligência artificial para contagem de peixes com base na imagem</h2>
              <div class="text-lg-start" style="text-align: left;">
                <a href="#contact" class="btn-get-started scrollto">Iniciar contagem</a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 order-1 order-lg-2 hero-img">
            <img src="../../public/peixes_favicon.png" class="img-fluid animated" alt="">
          </div>
        </div>
      </div>
    <svg class="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
    </svg> 
    </section>
  </template>
  
  <script>
  export default {
    name: 'HomeCardLogged',
    methods: {
    },
    mounted() {
    },
  }
  </script>
  
  