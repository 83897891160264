<template>
<section id="about" class="about">
      <div class="container-fluid">
        <div class="row">

            <div class="col-xl-5 col-lg-6 d-flex justify-content-center align-items-stretch">
            <img src="../../public/peixes_favicon.png" class="img-fluid animated" alt="">
            </div>
          <div class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
            <h3 style="text-align: left;">Sobre nós</h3>
            <p style="text-align: left;">Somos uma empresa Startup que utiliza inteligência artificial para a contagem de peixes. Com soluções precisas e acessíveis, nossa equipe de estudantes universitários apaixonados por tecnologia busca contribuir para a sustentabilidade ambiental.</p>

            <div class="icon-box" data-aos-delay="100">
              <div class="icon"><i class="bx bx-fingerprint"></i></div>
              <h4 class="title" style="text-align: left;"><a href="">Software como serviço (saas)</a></h4>
              <p class="description" style="text-align: left;">Desenvolver produtos e serviços tecnológicos para a aquicultura por meio de técnicas de Inteligência Artificial conectada a plataforma web.</p>
            </div>

            <div class="icon-box" data-aos-delay="200">
              <div class="icon"><i class="bx bx-gift"></i></div>
              <h4 class="title" style="text-align: left;"><a href="">Acompanhamento on-line</a></h4>
              <p class="description" style="text-align: left;">Técnica de Inteligência Artificial conectada a plataforma web possibilitando o acompanhamento dos dados em tempo real</p>
            </div>

            <div class="icon-box" data-aos-delay="300">
              <div class="icon"><i class="bx bx-atom"></i></div>
              <h4 class="title" style="text-align: left;"><a href="">Reduzir os custos</a></h4>
              <p class="description" style="text-align: left;">Controle de processos por meio da automatização</p>
            </div>

          </div>
        </div>

      </div>
    </section>
</template>
  
<script>
export default {
    name: 'SobreCard',
    methods: {
    },
    mounted() {
    },
}
</script>
  