<template>
  <HeaderSign />
  <!-- Aleta de senha incorreta -->
  <div v-if="showError" class="alert alert-danger alert-dismissible fade show" role="alert">
    Senha incorreta ou usuário não cadastrado
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
  <section class=" gradient-form" style="background-color: #eee;">
   <div class="container py-5 h-100">
     <div class="row d-flex justify-content-center align-items-center h-100">
       <div class="col-xl-10">
         <div class="card rounded-3 text-black">
           <div class="row g-0">
             <div class="col-lg-6">
               <div class="card-body p-md-5 mx-md-4">
 
                 <div class="text-rigth">
                   <img src="../../public/img/onpeixes.png"
                     style="width: 285px; padding-top: 3rem; padding-bottom: 3rem;" alt="logo">
                 </div>

                 
 
                 <form>
                   <p style="text-align: left;">E-mail:</p>
 
                   <div class="form-outline mb-4">
                     <!-- <label class="form-label " style="text-align: left;" for="form2Example11">E-mail:</label> -->
                     <input type="email" id="email" class="form-control" v-model="email"/>
                   </div>
 
                   <p style="text-align: left;">Senha:</p>
                   <!-- <p>Informe sua senha</p> -->
                   <div class="form-outline mb-4">
                     <!-- <label class="form-label" for="form2Example22"></label> -->
                     <input type="password" id="password" class="form-control" v-model="password"/>
                   </div>
 
                   <div class="text-center pt-1 mb-5 pb-1">
                    <button class="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3" type="button" @click="login()">Entrar</button>
                     <a class="text-muted" href="#!">Esqueceu a senha?</a>
                   </div>
 
                  </form>
                   <div class="d-flex align-items-center justify-content-center pb-4">
                     <p class="mb-0 me-2" style="padding-right: 1rem;">Não tem um cadastro?</p>
                     <button type="button" class="btn btn-outline-primary" @click="goToCadastro">Cadastrar</button>
                   </div>
 
 
 
               </div>
             </div>
             <div class="col-lg-6 d-flex align-items-center gradient-custom-2">
               <div class="text-white px-3 py-4 p-md-5 mx-md-4">
                 <h4 class="mb-4">O que é a OnPeixes?</h4>
                 <p class="small mb-0" style="text-align: justify;">OnPeixes é uma empresa Startup que utiliza inteligência artificial para a contagem de peixes. Com soluções precisas e acessíveis, nossa equipe de estudantes universitários apaixonados por tecnologia busca contribuir para a sustentabilidade ambiental.</p>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </section>
 <Footer />
</template>

<script>
import HeaderSign from '../components/HeaderSign.vue';
import Footer from '../components/Footer.vue';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      showError: false
    }
  },
  methods: {
    
    login() {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(() => {
          this.$router.push({ name: 'homelogged' })
        })
        .catch((error) => {
          console.error(error)
          this.showError = true
        })
    },
    goToCadastro() {
      this.$router.push('/cadastro')
    }
  },
  components: {
      HeaderSign,
      Footer
    },
}
</script>

   
<style>
@import "@/assets/css/login.css";
</style>